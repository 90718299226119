/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:ba701073-ecc2-45f9-82ea-e9617a7e408d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_E4ahkNFqS",
    "aws_user_pools_web_client_id": "1q3pubbrt2likaktp8sddm8nl4",
    "oauth": {
        "domain": "accessamplify68dbd1c9-68dbd1c9-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://wv6dhnc4mzbojdhuox4elwkio4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
