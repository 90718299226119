/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPersonEvent = /* GraphQL */ `
  query GetPersonEvent($id: ID!, $createdAt: AWSDateTime!) {
    getPersonEvent(id: $id, createdAt: $createdAt) {
      id
      comment
      eventType
      personId
      createdAt
      person {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listPersonEvents = /* GraphQL */ `
  query ListPersonEvents(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelPersonEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPersonEvents(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        comment
        eventType
        personId
        createdAt
        person {
          id
          name
          email
          personRole
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      name
      email
      personRole
      personEvents {
        items {
          id
          comment
          eventType
          personId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPersons = /* GraphQL */ `
  query ListPersons(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byPersonId = /* GraphQL */ `
  query ByPersonId(
    $personId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPersonId(
      personId: $personId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        comment
        eventType
        personId
        createdAt
        person {
          id
          name
          email
          personRole
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const byEventType = /* GraphQL */ `
  query ByEventType(
    $eventType: PersonEventType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventType(
      eventType: $eventType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        comment
        eventType
        personId
        createdAt
        person {
          id
          name
          email
          personRole
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const personsByEmail = /* GraphQL */ `
  query PersonsByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personsByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
