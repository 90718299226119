import React, { Component } from 'react';
import './PeopleDashboard.css';
import PersonPicker from './PersonPicker';
import PersonView from './PersonView';
import { API, graphqlOperation } from "aws-amplify";
import { listPersons } from "../graphql/queries";
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

async function getPeople() {
    return API.graphql(graphqlOperation(listPersons));
}

class PeopleDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personsList: []
        }
        this.setPerson = this.setPerson.bind(this);
    }
    componentDidMount() {
        getPeople().then((evt) => {
            if (evt.data && evt.data.listPersons) {
                this.setState({personsList: evt.data.listPersons.items});
            }
        });
    }
    setPerson(selectedPerson) {
        this.props.history.push("/people/" + selectedPerson.id);
    }
    render() {
        return (
            <div className="PeopleDashboard">
                <div className="PeopleDashboard-new-person">
                    <Link to='/people/new'>
                        <Button>Add New Person</Button>
                    </Link>
                </div>
                <div>
                    <PersonPicker setPerson={this.setPerson}/>
                </div>
                <div className="PeopleDashboard-person-list">
                    <h3>People</h3>
                    {
                        this.state.personsList.map((person) => {
                            return (<PersonView key={person.id} person={person}/>);
                        })
                    }
                </div>
            </div>
        )
    }
}

const PeopleDashboardWithRouter = withRouter(PeopleDashboard);
export default PeopleDashboardWithRouter;