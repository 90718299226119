/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPersonEvent = /* GraphQL */ `
  mutation CreatePersonEvent(
    $input: CreatePersonEventInput!
    $condition: ModelPersonEventConditionInput
  ) {
    createPersonEvent(input: $input, condition: $condition) {
      id
      comment
      eventType
      personId
      createdAt
      person {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updatePersonEvent = /* GraphQL */ `
  mutation UpdatePersonEvent(
    $input: UpdatePersonEventInput!
    $condition: ModelPersonEventConditionInput
  ) {
    updatePersonEvent(input: $input, condition: $condition) {
      id
      comment
      eventType
      personId
      createdAt
      person {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deletePersonEvent = /* GraphQL */ `
  mutation DeletePersonEvent(
    $input: DeletePersonEventInput!
    $condition: ModelPersonEventConditionInput
  ) {
    deletePersonEvent(input: $input, condition: $condition) {
      id
      comment
      eventType
      personId
      createdAt
      person {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      name
      email
      personRole
      personEvents {
        items {
          id
          comment
          eventType
          personId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      name
      email
      personRole
      personEvents {
        items {
          id
          comment
          eventType
          personId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      name
      email
      personRole
      personEvents {
        items {
          id
          comment
          eventType
          personId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
