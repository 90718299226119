/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePersonEvent = /* GraphQL */ `
  subscription OnCreatePersonEvent {
    onCreatePersonEvent {
      id
      comment
      eventType
      personId
      createdAt
      person {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdatePersonEvent = /* GraphQL */ `
  subscription OnUpdatePersonEvent {
    onUpdatePersonEvent {
      id
      comment
      eventType
      personId
      createdAt
      person {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeletePersonEvent = /* GraphQL */ `
  subscription OnDeletePersonEvent {
    onDeletePersonEvent {
      id
      comment
      eventType
      personId
      createdAt
      person {
        id
        name
        email
        personRole
        personEvents {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreatePerson = /* GraphQL */ `
  subscription OnCreatePerson {
    onCreatePerson {
      id
      name
      email
      personRole
      personEvents {
        items {
          id
          comment
          eventType
          personId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePerson = /* GraphQL */ `
  subscription OnUpdatePerson {
    onUpdatePerson {
      id
      name
      email
      personRole
      personEvents {
        items {
          id
          comment
          eventType
          personId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePerson = /* GraphQL */ `
  subscription OnDeletePerson {
    onDeletePerson {
      id
      name
      email
      personRole
      personEvents {
        items {
          id
          comment
          eventType
          personId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
