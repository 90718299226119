import React, { Component } from 'react';
import logo from '../images/aba-logo2.png';
import './AuthView.css';
import { Auth } from "aws-amplify";
import { Form, Button } from 'react-bootstrap';

class UsernameInput extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        this.props.updateUsername(e.target.value);
    }
    render() {
        return (
            <Form.Group controlId="login-username">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    label="Email"
                    onChange={this.handleChange}
                />
                <Form.Text className="validation-error has-error">{this.props.validationErrors}</Form.Text>
            </Form.Group>
        );
    }
}

class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        this.props.updatePassword(e.target.value);
    }
    render() {
        return (
            <Form.Group controlId="login-password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        label="Password"
                        onChange={this.handleChange}
                    />
                <Form.Text className="validation-error has-error">{this.props.validationErrors}</Form.Text>
            </Form.Group>
        );
    }
}

class AuthView extends Component {
  constructor(props) {
      super(props);
      this.state = {
          username: "",
          password: ""
      };
      this.updateUsername = this.updateUsername.bind(this);
      this.updatePassword = this.updatePassword.bind(this);
      this.handleLogin = this.handleLogin.bind(this);
      this.validateForm = this.validateForm.bind(this);
  }
  componentDidMount() {
  }
  updateUsername(username) {
      this.setState({'username': username});
  }
  updatePassword(password) {
      this.setState({'password': password});
  }
  handleLogin(event) {
    event.preventDefault();

    const validationErrors = this.validateForm();
    const errorCount = Object.keys(validationErrors).length;
    if (errorCount) {
        this.setState({'showErrors': true});
        return;
    }

    Auth.signIn(this.state.username, this.state.password)
        .then(() => { window.location.reload(); });  
  }
  validateForm() {
      let validationErrors = {};
      if (!this.state.username || (this.state.username.indexOf("@accessba.com")) === -1) {
          validationErrors.username = "Enter your accessba email address"
      }
      if (!this.state.password) {
          validationErrors.password = "Enter your password"
      }
      return validationErrors;
  }
  render() {
    const validationErrors = this.validateForm();
    const showErrorsClass = this.state.showErrors ? 'show-errors' : '';
    const className = `AuthView ${showErrorsClass}`;

    if (!this.state.authenticatedUser) {
      return (
          <div className={className}>
              <Form className="login" onSubmit={this.handleLogin}>
                <div className="AuthView-logo">
                  <img src={logo} alt={`Access logo`}/>
                </div>
                <UsernameInput updateUsername={this.updateUsername} validationErrors={validationErrors.username}/>
                <PasswordInput updatePassword={this.updatePassword} validationErrors={validationErrors.password}/>
                <Form.Group>
                    <div>
                        <Button type="submit">Sign In</Button>
                    </div>
                </Form.Group>
              </Form>
          </div>
      );
    }

    return (
      <div className="AuthView">
      </div>
    );
  }
}

export default AuthView;
