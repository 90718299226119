import React, { Component } from 'react';
import PersonView from '../persons/PersonView';
import moment from 'moment';
import './PersonEventView.css';

const PERSONEVENTTYPES = {
    tardy: "Tardy",
    missedContactNote: "Missed contact note",
    missedTreatmentHours: "Missed treatment hours"
}


class PersonEventView extends Component {
    render() {
        const personEvent = this.props.personEvent;
        let personEventTypeName = PERSONEVENTTYPES[personEvent.eventType];
        const variant = this.props.variant || "odd";
        const className = "PersonEvent " + variant;
        return (
            <div className={className}>
                <dl className="row">
                    <dt className="col-sm-1">When</dt>
                    <dd className="col">{moment(personEvent.createdAt).format('llll')}</dd>
                </dl>
                <dl className="row">
                    <dt className="col-sm-1">Type</dt>
                    <dd className="col">{personEventTypeName}</dd>
                </dl>
                {this.props.expandPerson &&
                    <dl className="row">
                        <dt className="col-sm-1">Who</dt>
                        <dd className="col"><PersonView expandEvents={false} personId={personEvent.personId}/></dd>
                    </dl>
                }
                <dl className="row">
                    <dt className="col-sm-1">Comment</dt>
                    <dd className="col">{personEvent.comment}</dd>
                </dl>
            </div>
        )
    }
}

export default PersonEventView;