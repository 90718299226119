import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

const PERSONROLES = {
    employee: "Employee",
    supervisor: "Supervisor",
    admin: "Admin"
}

class PersonRolePicker extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(eventKey) {
        this.props.setSelected(eventKey.target.value);
    }
    render() {
        let pickerId = "person-role-picker";
        return (
            <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                    as="select"
                    className="PersonRolePicker"
                    id={pickerId}
                    onChange={this.handleSelect}
                >
                {
                    Object.keys(PERSONROLES).map((role, i) => {
                        const roleName = PERSONROLES[role];
                        return (
                            <option key={role} value={role}>{roleName}</option>
                        );
                    })
                }
                </Form.Control>
            </Form.Group>
        )
    }
}



export default PersonRolePicker;