import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

const PERSONEVENTTYPES = {
    tardy: "Tardy",
    missedContactNote: "Missed contact note",
    missedTreatmentHours: "Missed treatment hours"
}

class PersonEventTypePicker extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(evt) {
        this.props.setPersonEventType(evt.target.value);
    }
    render() {
        let pickerId = "person-event-type-picker";
        return (
            <Form.Control
                as="select"
                className="PersonEventTypePicker"
                id={pickerId}
                onChange={this.handleSelect}
                >
                {
                    Object.keys(PERSONEVENTTYPES).map((eventType, i) => {
                        const eventTypeName = PERSONEVENTTYPES[eventType];
                        return (
                            <option key={eventType} value={eventType}>{eventTypeName}</option>
                        );
                    })
                }
            </Form.Control>
        )
    }
}

export default PersonEventTypePicker;