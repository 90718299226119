import React, { Component } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { createPerson } from "../graphql/mutations";
import { onCreatePerson } from "../graphql/subscriptions";
import { personsByEmail } from "../graphql/queries";
import PersonRolePicker from './PersonRolePicker';
import './AddPerson.css';

async function createNewPerson(person) {
    return await API.graphql(graphqlOperation(createPerson, { input: person }))
    .then((evt) => {
        console.log(evt);
    });
}

async function findPersonByEmail(email) {
    return await API.graphql(graphqlOperation(personsByEmail, { email: email }))
    .then((evt) => {
        return evt.data.personsByEmail;
    });
}

class PersonEmail extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.props.setPersonEmail(event.target.value);
    }
    render() {
        return (
            <Form.Group className="PersonEmail" controlId="person-email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="name@accessba.com"
                    value={this.props.personEmail}
                    onChange={this.handleChange}
                    >
                </Form.Control>
                <Form.Text className="validation-error has-error">{this.props.validationErrors}</Form.Text>
            </Form.Group>
        );
    }
}

class PersonName extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.props.setPersonName(event.target.value);
    }
    render() {
        return (
            <Form.Group className="PersonName" controlId="person-name">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Firstname Lastname"
                    value={this.props.personName}
                    onChange={this.handleChange}
                    >
                </Form.Control>
                <Form.Text className="validation-error has-error">{this.props.validationErrors}</Form.Text>
            </Form.Group>
        );
    }
}

  class AddPerson extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: "",
            email: "",
            personRole: "employee"
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.setPersonEmail = this.setPersonEmail.bind(this);
        this.setPersonName = this.setPersonName.bind(this);
        this.setPersonRole = this.setPersonRole.bind(this);
    }
    componentDidMount() {
        API.graphql(graphqlOperation(onCreatePerson)).subscribe({
            next: (evt) => {
                const personEvent = evt.value.data.onCreatePerson;
                console.log(personEvent);
            },
        });
    }
    onSubmit(evt) {
        evt.preventDefault();
        findPersonByEmail(this.state.email).then((persons) => {
            if (persons && persons.items && persons.items.length) {
                console.log("There is already a person with this email address.");
            } else {
                return createNewPerson(this.state);
            }
        });
    }
    setPersonEmail(newPersonEmail) {
        this.setState({email: newPersonEmail});
    }
    setPersonName(newPersonName) {
        this.setState({name: newPersonName});
    }
    setPersonRole(newPersonRole) {
        this.setState({personRole: newPersonRole});
    }
    render() {
        return (
            <div className="AddPerson">
                <Form onSubmit={this.onSubmit}>
                    <PersonEmail personEmail={this.state.email} setPersonEmail={this.setPersonEmail}/>
                    <PersonName personName={this.state.name} setPersonName={this.setPersonName}/>
                    <Form.Group>
                        <PersonRolePicker personRole={this.state.personRole} setSelected={this.setPersonRole}/>
                    </Form.Group>
                    <div className="AddPerson-submit">
                        <Button type="submit">
                            Add New Person
                        </Button>
                        <Link className="AddPerson-cancel" to="/people">
                            Cancel
                        </Link>
                    </div>
                </Form>
            </div>
        );
    }
}



export default AddPerson;