import React, { Component } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { getPerson, byPersonId } from "../graphql/queries";
import PersonEventView from '../personEvents/PersonEventView'
import { Link } from "react-router-dom";
import { withRouter } from 'react-router'
import './PersonView.css'

async function awaitGetPerson(personId) {
    return await API.graphql(graphqlOperation(getPerson, { id: personId }));
}

async function getEventsForPerson(personId) {
    return await API.graphql(graphqlOperation(byPersonId, { personId: personId, limit: 25 }));
}

class PersonView extends Component {
    constructor(props) {
        super(props);

        let personId = this.props.personId;
        if (!personId) {
            personId = this.props.match.params.personId;
            if (!personId && this.props.person) {
                personId = this.props.person.id;
            }
        }

        this.state = {
            personId: personId,
            person: this.props.person,
            personEvents: []
        };
    }
    componentDidMount() {
        if (!this.state.person && this.state.personId) {
            awaitGetPerson(this.state.personId)
            .then((evt) => {
                this.setState({person: evt.data.getPerson});
            });
        }
        if (this.state.personId) {
            const personId = this.state.personId;
            if (this.props.expandEvents) {
                getEventsForPerson(personId)
                .then((evt) => {
                    console.log(evt);
                    if (evt.data && evt.data.byPersonId) {
                        this.setState({personEvents: evt.data.byPersonId.items})
                    }
                })
            }
        }
    }
    render() {
        if (!this.state.person) {
            return (<div className="Person">User not found</div>)
        }
        const person = this.state.person;
        return (
            <div className="Person">
                <Link to={"/people/" + person.id}>
                    <span className="Person-name">{person.name}</span>
                </Link>
                <span className="Person-email">({person.email})</span>
                {
                    this.state.personEvents.map((personEvent, i) => {
                        const rowStyle = i % 2 === 0 ? "even" : "odd";
                        return <PersonEventView variant={rowStyle} expandPerson={false} key={personEvent.id} personEvent={personEvent}/>
                    })
                }
            </div>
        )
    }
}

const PersonViewWithRouter = withRouter(PersonView);
export default PersonViewWithRouter;