import React, { Component } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import {Button, Form} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import PersonPicker from '../persons/PersonPicker'
import { createPersonEvent } from "../graphql/mutations";
import PersonEventTypePicker from './PersonEventTypePicker';
import './AddPersonEvent.css'

async function createNewPersonEvent(personEvent) {
    return await API.graphql(graphqlOperation(createPersonEvent, { input: personEvent }))
      .then((evt) => {
        console.log(evt);
      });
  }

  class AddPersonEvent extends Component {
    constructor(props){
        super(props);
        this.state = {
            person: null,
            eventType: "tardy"
        };
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.setPerson = this.setPerson.bind(this);
        this.setPersonEventType = this.setPersonEventType.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }
    componentDidMount() {
    }
    onSubmit(evt) {
        evt.preventDefault();

        const validationErrors = this.validateForm();
        const errorCount = Object.keys(validationErrors).length;
        if (errorCount) {
            this.setState({'showErrors': true});
            return;
        }

        const personEvent = {
            personId: this.state.person.id,
            eventType: this.state.eventType,
            comment: this.state.comment
          };
          return createNewPersonEvent(personEvent).then(() => {
              this.props.history.push("/events");
          });
    }
    handleCommentChange(event) {
        this.setState({comment: event.target.value});
    }
    setPerson(person) {
        this.setState({person: person})
    }
    setPersonEventType(personEventType) {
        this.setState({eventType: personEventType});
    }
    validateForm() {
        let validationErrors = {};
        if (!this.state.person) {
            validationErrors.personPicker = "Select a person"
        }
        return validationErrors;
    }
    render() {
        const validationErrors = this.validateForm();
        const showErrorsClass = this.state.showErrors ? 'show-errors' : '';
        const className = `AddPersonEvent ${showErrorsClass}`;

        return (
            <Form className={className} onSubmit={this.onSubmit}>
                <Form.Group>
                    <Form.Label>Event Type</Form.Label>
                    <PersonEventTypePicker setPersonEventType={this.setPersonEventType}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Person</Form.Label>
                    <PersonPicker setPerson={this.setPerson} validationErrors={validationErrors.personPicker}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Comment</Form.Label>
                    <Form.Control as="textarea" onChange={this.handleCommentChange}>
                    </Form.Control>
                </Form.Group>

                <Button type="submit">
                    Add New Event
                </Button>
                <Link className="AddPersonEvent-cancel" to="/events">
                    Cancel
                </Link>
            </Form>
        );
    }
}



export default withRouter(AddPersonEvent);