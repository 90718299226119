import React, { Component } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { Button, Form, InputGroup } from 'react-bootstrap';
import PersonView from './PersonView'
import './PersonPicker.css'

import { personsByEmail } from "../graphql/queries";

async function findPersonByEmail(email) {
    return await API.graphql(graphqlOperation(personsByEmail, { email: email, limit: 1 }))
    .then((evt) => {
        return evt.data.personsByEmail;
    });
}

class PersonPicker extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            person: null
        };

        this.findPerson = this.findPerson.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }
    findPerson(evt) {
        const self = this;
        evt.preventDefault();
        if (!this.state.email) {
            return;
        }

        findPersonByEmail(this.state.email).then((persons) => {
            if (persons && persons.items && persons.items.length) {
                self.setState({person: persons.items[0]});
                if (this.props.setPerson) {
                    this.props.setPerson(persons.items[0]);
                }
            } else {
                console.log("Person not found.")
            }
        });
    }
    handleEmailChange(event) {
        const newPersonEmail = event.target.value;
        this.setState({email: newPersonEmail});
    }
    selectPerson() {
        if (this.props.setPerson) {
            this.props.setPerson(this.state.person);
        }
    }
    render() {
        return (
            <div className="PersonPicker">
                {!this.state.person
                    ? <div>
                        <Form.Group className="PersonEmail" controlId="person-email">
                            <InputGroup>
                                <Form.Control
                                    className="PersonEmail-input"
                                    type="email"
                                    placeholder="name@accessba.com"
                                    value={this.props.personEmail}
                                    onChange={this.handleEmailChange}
                                    >
                                </Form.Control>
                                <Button variant="outline-secondary" type="button" onClick={this.findPerson}>
                                    Find Person
                                </Button>
                            </InputGroup>
                            <Form.Text className="validation-error has-error">{this.props.validationErrors}</Form.Text>
                        </Form.Group>
                    </div>
                    : <div>
                        <PersonView person={this.state.person}/>
                    </div>
                }
            </div>
        );
    }
}



export default PersonPicker;