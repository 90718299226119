import React, { Component } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { Table, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import PersonView from '../persons/PersonView';
import moment from 'moment';
import './PersonEventsDashboard.css';

import { byEventType } from "../graphql/queries";

async function getEventsByEventTypeAfter(eventType, minDateString) { 
    const query = {
        eventType: eventType,
        createdAt: {
            gt: minDateString
        },
        limit: 1000
    };

    return await API.graphql(graphqlOperation(byEventType, query));
}

const PERSONEVENTTYPES = {
    tardy: "Tardy",
    missedContactNote: "Missed contact note",
    missedTreatmentHours: "Missed treatment hours"
}

class PersonEventsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issues: {},
            people: {}
        }
    }
    componentDidMount() {
        const self = this;
        const ninetyDaysAgo = moment().startOf('day').subtract(90, 'day');

        Object.keys(PERSONEVENTTYPES).map((eventType) => {
            return getEventsByEventTypeAfter(eventType, ninetyDaysAgo.toISOString())
            .then((evt) => {
                if (evt.data.byEventType) {
                    let stateClone = JSON.parse(JSON.stringify(this.state));

                    const newState = evt.data.byEventType.items.reduce((accumulator, currentValue) => {
                        const personId = currentValue.personId;
                        const eventType = currentValue.eventType;

                        if (!accumulator[personId]) accumulator[personId] = {};
                        if (!accumulator[personId][eventType]) accumulator[personId][eventType] = [];

                        let eventsByPersonAndType = accumulator[personId][eventType];
                        eventsByPersonAndType.push(currentValue);
                        if (eventsByPersonAndType.length >= 3) {
                            if (!accumulator.issues[personId]) accumulator.issues[personId] = {};
                            accumulator.issues[personId][eventType] = eventsByPersonAndType.length;
                        }

                        return accumulator;
                    }, stateClone);

                    self.setState(newState);
                    return newState;
                }
            });
        });
    }
    render() {
        return (
            <div className="PersonEventsDashboard">
            <div className="PersonEventsDashboard-new-event">
                <Link to='/events/new'>
                    <Button>Add New Event</Button>
                </Link>
            </div>
            <Table striped responsive size="sm" className="PersonEventsDashboard-event-table">
                <thead>
                    <tr>
                        <th>Employee</th>
                        <th>{PERSONEVENTTYPES.tardy}</th>
                        <th>{PERSONEVENTTYPES.missedContactNote}</th>
                        <th>{PERSONEVENTTYPES.missedTreatmentHours}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(this.state.issues).map((personId) => {
                            const issue = this.state.issues[personId];
                            return (
                            <tr key={personId}>
                                <td>
                                    <PersonView personId={personId}/>
                                </td>
                                <td>
                                    { issue['tardy'] }
                                </td>
                                <td>
                                    { issue['missedContactNote'] }
                                </td>
                                <td>
                                    { issue['missedTreatmentHours'] }
                                </td>
                            </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            </div>
        )
    }
}

export default PersonEventsDashboard;