import React, { Component } from 'react';
import logo from './images/aba-logo2.png';
import './App.css';
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Navbar, Nav, Form, Button } from 'react-bootstrap';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AddPersonEvent from './personEvents/AddPersonEvent';
import PeopleDashboard from './persons/PeopleDashboard';
import PersonEventsDashboard from './personEvents/PersonEventsDashboard';
import PersonView from './persons/PersonView';
import AddPerson from './persons/AddPerson';
import AuthView from './authview/AuthView';

Amplify.configure(awsconfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticatedUser: null
    };
    this.signOut = this.signOut.bind(this);
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser().then((authenticatedUser) => {
      this.setState({"authenticatedUser": authenticatedUser});
    }).catch(() => {
      this.setState({"authenticatedUser": null});
    });
  }
  signOut() {
    Auth.signOut().then(() => {
      window.location.reload();
    });
  }
  render() {
    if (!this.state.authenticatedUser) {
      return (
          <div className="App-login">
            <AuthView />
          </div>
      );
    }

    return (
      <div className="App">
          <Router>
            <div>
              <div className="App-header">
                  <img src={logo} alt={`Access logo`}/>
              </div>
              <Navbar className="App-header">
                <Nav.Link href="/people">
                  People
                </Nav.Link>
                <Nav.Link href="/events">
                  Events
                </Nav.Link>
                <Form inline>
                  <Button onClick={this.signOut}>
                    Sign Out
                  </Button>
                </Form>
              </Navbar>
              <Switch>
                  <Route exact path="/events/new" render={(props) => (
                      <AddPersonEvent />
                  )} />
                  <Route exact path="/events" render={(props) => (
                      <PersonEventsDashboard />
                  )} />
                  <Route exact path="/people/new" render={(props) => (
                      <AddPerson />
                  )} />
                  <Route exact path="/people/:personId" render={(props) => (
                      <PersonView expandEvents={true} />
                  )} />
                  <Route exact path="/people" render={(props) => (
                      <PeopleDashboard />
                  )} />
                  <Redirect to="events"></Redirect>
              </Switch>
            </div>
          </Router>
      </div>
    );
  }
}

export default App;
